import { url } from '@/config';
import crypto from '@/utils/crypto'
import request from '../axios';
export const getList = (current, size, params) => {
  return request({
    url: url + '/visual/db/list',
    method: 'get',
    params: {
      ...params,
      limit:current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: url + `/visual/db/${id}/detail`,
    method: 'get',
  })
}

export const remove = (ids) => {
  return request({
    url: url + `/visual/db/${ids}`,
    method: 'delete',
  })
}

export const add = (row) => {
  return request({
    url: url + '/visual/db/add',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: url + `/visual/db/${row.id}`,
    method: 'put',
    data: row
  })
}
export const dynamicSql = (data) => {
  return request({
    url: url + '/visual/db/dynamic-query',
    method: 'post',
    headers: {
      'ModuleId': data,
      // 'Content-Type': 'application/json'
    },
    data
  })
}
export const dbTest = (data) => {
  return request({
    url: url + '/visual/db/db-test',
    method: 'post',
    data: data
  })
}