import { url } from '@/config';
import request from '../axios'
export const getList = (params) => request({
  url: url + '/visual/category/list',
  method: 'get',
  params: params
});



export const getObj = (id) => request({
  url: url + '/visual/category/detail',
  method: 'get',
  params: {
    id
  }
});

export const addObj = (data) => request({
  url: url + '/visual/category',
  method: 'post',
  data: data
});

export const updateObj = (data) => request({
  url: url + `/visual/category/${data.id}`,
  method: 'post',
  data: data
});



export const delObj = (id) => request({
  url: url + `/visual/category/${id}`,
  method: 'delete',
});