import { url } from '@/config';
import request from '../axios'
export const getList = (params) => request({
  url: url + '/visual/map/list',
  method: 'get',
  params: params
});



export const getObj = (id) => request({
  url: url + `/visual/map/detail/${id}`,
  method: 'get',
});

export const addObj = (data) => request({
  url: url + '/visual/map',
  method: 'post',
  data: data
});

export const updateObj = (data) => request({
  url: url + `/visual/map/${data.id}`,
  method: 'put',
  data: data
});



export const delObj = (id) => request({
  url: url + `/visual/map/${id}`,
  method: 'delete',
});