import { url } from '@/config';
import request from '../axios'

/* 查询所有子系统 */
export const getSubSystem = () => request({
    url: url + `/subsystem`,
    method: 'get'
});

/* 新增 */
export const addSubSystem = (param) => request({
    url: url + `/subsystem`,
    method: 'post',
    params: param
});

/* 删除 */
export const deleteSubSystem = (systemIds) => request({
    url: url + `/subsystem/${systemIds}`,
    method: 'delete'
});

/* 修改 */
export const updateSubSystem = (systemId,param) => request({
    url: url + `/subsystem/${systemId}`,
    method: 'put',
    params: param
});

